import {
  Chat,
  Close,
  DeleteOutlineOutlined,
  Percent,
  Person,
  Policy,
  Rule,
  Today,
} from "@mui/icons-material";
import {
  Chip,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { APIActions } from "../../../../api/actions";
import DialogAlert from "../../../../classes/DialogAlert";
import Baseline from "../../../../components/form/Baseline";
import UserListItem from "../../../../components/USERS/UserListItem";
import { setError } from "../../../../store/features/base/errorBaseSlice";
import { openSnackbar } from "../../../../store/features/base/snackbarBaseSlice";
import { updateExpense } from "../../../../store/features/expensesSlice";
import formatAmount from "../../../../utils/formatAmount";
import { curr } from "../../../../utils/more/currency_country";
import { formatMoment } from "../../../../utils/more/date_functions";

const DiscountIndicator = ({
  expenseId,
  role,
  originalAmount = {},
  displayAmount = {},
  onRefresh = () => {},
}) => {
  const dispatch = useDispatch();
  const { discount, currency, amount } = originalAmount;

  const [menu, setMenu] = useState(null);

  const closeMenu = () => setMenu(null);

  const handleDeleteClick = () => {
    DialogAlert.show({
      disableCloseButton: true,
      title: "Excluir desconto",
      message:
        "Ao remover o desconto da despesa, seu valor original será restaurado.",
      actions: [
        {
          text: "Cancelar",
        },
        {
          text: "Excluir",
          main: true,
          onClick: async () => {
            try {
              const expense = await APIActions.expenses.removeDiscount({
                expenseId,
                role,
              });
              dispatch(
                updateExpense({
                  id: expenseId,
                  role,
                  changes: { ...expense },
                })
              );
              dispatch(
                openSnackbar({
                  message: "Desconto removido",
                })
              );
              onRefresh(expense);
              closeMenu();
            } catch (error) {
              dispatch(setError({ error }));
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <Baseline
        sx={{ mt: 0.5 }}
        Icon={() => {}}
        value={
          <Chip
            onClick={(e) => setMenu(e.target)}
            component={"span"}
            icon={<Percent sx={{ fontSize: "1rem" }} />}
            clickable
            sx={{
              bgcolor: "elevation1.main",
              height: 30,
              fontWeight: 600,
              borderRadius: 100,
              transition: "none",
            }}
            label={`Desconto de: ${curr(currency)} ${formatAmount(
              discount?.amount
            )}`}
          />
        }
      />
      {Boolean(menu) && (
        <Popover
          anchorEl={menu}
          open={Boolean(menu)}
          onClose={closeMenu}
          transitionDuration={0}
          TransitionProps={{
            unmountOnExit: true,
          }}
          slotProps={{
            paper: {
              sx: {
                position: "relative",
                width: 400,
                pb: 2,
              },
            },
          }}
        >
          <Stack gap={2} p={2}>
            <Baseline
              label={"Valor descontado"}
              value={
                <Typography fontSize={"1.05rem"} fontWeight={"600"}>
                  {curr(currency)} {formatAmount(discount?.amount)}
                </Typography>
              }
            />
            <Baseline
              sx={{ mt: -1 }}
              label={"Valor final (convertido)"}
              value={
                <Typography
                  color="primary"
                  fontSize={"1.05rem"}
                  fontWeight={"600"}
                >
                  {curr(displayAmount?.currency)}{" "}
                  {formatAmount(displayAmount?.amount)}
                </Typography>
              }
            />
            <Divider />
            <Baseline
              label={"Observações"}
              Icon={Chat}
              value={discount?.reason}
              emptyLabel={"Não informado"}
            />
            {discount?.origin?.source === "user" ? (
              <>
                <Baseline
                  Icon={Person}
                  label={"Aplicado por"}
                  value={
                    <UserListItem
                      clickable={false}
                      disableGutters
                      dense
                      user={discount?.origin?.user || {}}
                    />
                  }
                  emptyLabel={"--"}
                />
              </>
            ) : discount?.origin?.source === "rule" ? (
              <>
                <Baseline
                  Icon={Rule}
                  label={"Aplicado pela regra"}
                  value={discount?.origin?.ruleName}
                  emptyLabel={"--"}
                />
              </>
            ) : discount?.origin?.source === "audit" ? (
              <Baseline
                label={"Aplicado pelo Auditor"}
                sx={{ ml: -0.5, my: 2, alignItems: "flex-start" }}
                Icon={(p) => <Policy {...p} color="primary" />}
                value={"Auditor Notepay"}
              />
            ) : (
              <></>
            )}
            <Baseline
              Icon={Today}
              label={"Descontado em"}
              value={formatMoment(discount?.createdAt)}
              emptyLabel={"--"}
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ position: "absolute", top: 5, right: 5 }}
          >
            {role !== "personal" && (
              <IconButton onClick={handleDeleteClick}>
                <DeleteOutlineOutlined />
              </IconButton>
            )}
            <IconButton onClick={closeMenu}>
              <Close />
            </IconButton>
          </Stack>
        </Popover>
      )}
    </>
  );
};

export default memo(DiscountIndicator);
