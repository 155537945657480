import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import Baseline from "../../../../components/form/Baseline";
import formatAmount from "../../../../utils/formatAmount";
import { curr, CURRENCY_LIST } from "../../../../utils/more/currency_country";
import { CurrencyInputMask } from "../../../../utils/more/mask_functions";

const SelectCurrencyMenu = ({ currency, onChange, disabled }) => {
  const [menu, setMenu] = useState(null);

  const handleClickMenuItem = (v) => {
    onChange(v);
    setMenu(null);
  };

  return (
    <>
      <Box
        onClick={(e) => !disabled && setMenu(e.target)}
        borderRadius={1}
        p={0.5}
        pr={disabled ? 1 : 0.5}
        sx={{
          cursor: disabled ? "default" : "pointer",
          ":hover": { bgcolor: "action.hover" },
        }}
        display={"flex"}
        alignItems={"center"}
      >
        <Typography
          flex={1}
          noWrap
          fontSize="1.7rem"
          variant="h5"
          color={disabled ? "text.secondary" : "text.primary"}
          fontWeight={"500"}
        >
          {curr(currency)}
        </Typography>
        {!disabled && <ArrowDropDown fontSize="small" />}
      </Box>
      <Menu
        transitionDuration={0}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={() => setMenu(null)}
      >
        {CURRENCY_LIST.map((item, idx) => (
          <MenuItem
            key={item.code + idx.toString()}
            selected={item.code === currency}
            onClick={() => handleClickMenuItem(item.code)}
            value={item.code}
          >
            {item.code} ({item.symbol})
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

function AmounInput({
  readOnly,
  onChange,
  autoFocus,
  onChangeCurrency,
  value = "",
  innherRef,
  currency,
  sx,
  isEditable = true,
  disabled,
  endAdornment,
  discounted,
  netAmount = 0,
}) {
  if (!isEditable) {
    return (
      <Baseline
        Icon={() => (
          <Typography fontSize={"1.2rem"} fontWeight={"500"}>
            {curr(currency)}
          </Typography>
        )}
        label={"Valor"}
        value={
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography
              sx={{ textDecoration: discounted ? "line-through" : "none" }}
              fontSize={"1.4rem"}
              color={discounted ? "textSecondary" : "textPrimary"}
              fontWeight={"600"}
            >
              {formatAmount(value)}
            </Typography>
            {discounted && Boolean(netAmount) && (
              <Typography fontSize={"1.4rem"} fontWeight={"600"}>
                {formatAmount(netAmount)}
              </Typography>
            )}
          </Stack>
        }
      />
    );
  } else {
    return (
      <>
        <Box sx={sx} display={"flex"} gap={1} alignItems="center">
          <SelectCurrencyMenu
            disabled={disabled || readOnly}
            currency={currency}
            onChange={onChangeCurrency}
          />
          <TextField
            name="amount"
            margin="dense"
            hiddenLabel
            required
            disabled={disabled}
            inputRef={innherRef}
            autoFocus={autoFocus}
            fullWidth
            variant={"standard"}
            size="small"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            placeholder="0,00"
            InputProps={{
              readOnly,
              sx: {
                fontSize: "1.8rem",
                fontWeight: "500",
                borderRadius: 1,
              },
              inputComponent: CurrencyInputMask,
            }}
          />
          {endAdornment && (
            <Box display={"flex"} alignItems={"center"} pl={1}>
              {endAdornment}
            </Box>
          )}
        </Box>
      </>
    );
  }
}

export default memo(AmounInput);
