import { SendOutlined } from "@mui/icons-material";
import { Box, Button, DialogActions, Skeleton } from "@mui/material";
import React, { memo } from "react";
import FinishExpensesWrapper from "../../../actions/expenses/FinishExpensesWrapper";
import ApproveButton from "../../../components/buttons/ApproveButton";
import RejectButton from "../../../components/buttons/RejectButton";
import ApproveToggleButton from "./components/ApproveToggleButton";
import SendConfirmationWrapper from "./components/header/components/SendConfirmationWrapper";

const ActionsContent = ({
  loading,
  saving,
  changed,
  expenseId,
  onClose = () => {},
  onSend = () => {},
  onSave = () => {},
  onFinishSuccess = () => {},
  isEditable,
  isApprover,
  toFinish,
  discounted,
  originalAmount,
}) => {
  return (
    <DialogActions
      sx={{
        boxShadow: 4,
        height: 55,
        "& .MuiButtonBase-root": { height: 35 },
      }}
    >
      {loading ? (
        <>
          <Skeleton
            height={30}
            variant="rounded"
            sx={{ borderRadius: 100 }}
            width={100}
          />
          <Skeleton
            height={30}
            variant="rounded"
            sx={{ borderRadius: 100 }}
            width={100}
          />
          <Box flex={1} />
        </>
      ) : (
        <>
          {isApprover ? (
            <>
              {discounted ? (
                <ApproveButton
                  ids={[expenseId]}
                  onApprove={onClose}
                  type="expenses"
                />
              ) : (
                <ApproveToggleButton
                  expenseId={expenseId}
                  onClose={onClose}
                  originalAmount={originalAmount}
                />
              )}
              <RejectButton
                ids={[expenseId]}
                onApprove={onClose}
                type="expenses"
              />
              <Box flex={1} />
              <Button onClick={onClose}>Cancelar</Button>
            </>
          ) : (
            <>
              {toFinish ? (
                <FinishExpensesWrapper
                  role="financial"
                  onFinish={(expenses) => onFinishSuccess(expenses[0] || null)}
                  renderComponent={({ openModal }) => (
                    <Button
                      onClick={() => openModal([expenseId])}
                      sx={{ px: 3 }}
                      variant="contained"
                      disableElevation
                    >
                      Finalizar
                    </Button>
                  )}
                />
              ) : (
                <Button
                  onClick={onSave}
                  disabled={!changed || loading || saving}
                  sx={{ px: 4 }}
                  variant="contained"
                  disableElevation
                >
                  {saving ? "Salvando" : "Salvar"}
                </Button>
              )}
              <Button onClick={onClose}>Cancelar</Button>
              <Box flex={1} />
              {isEditable && (
                <SendConfirmationWrapper
                  onSendToApproval={onSend}
                  expensesIds={[expenseId]}
                  renderComponent={({ openModal }) => (
                    <Button
                      disabled={loading || saving}
                      onClick={openModal}
                      endIcon={<SendOutlined />}
                      variant="outlined"
                    >
                      Enviar
                    </Button>
                  )}
                />
              )}
            </>
          )}
        </>
      )}
    </DialogActions>
  );
};

export default memo(ActionsContent);
