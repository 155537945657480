import { CheckCircle, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import UserListItem from "../../../../components/USERS/UserListItem";

const StepApproverItem = memo(({ approver = {} }) => {
  const { status } = approver;

  return (
    <UserListItem
      dense
      disableGutters
      clickable={false}
      user={approver}
      rightContent={
        status === "approved" ? (
          <Chip
            icon={<CheckCircle />}
            variant="outlined"
            color="success"
            size="small"
            label="Aprovou"
            sx={{ fontWeight: "600" }}
          />
        ) : (
          status === "pending" && (
            <Tooltip title="Aguardando aprovação...">
              <div className="typing">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Tooltip>
          )
        )
      }
    />
  );
});

const StepApproversInfo = ({ currentStep, stepApprovers = [], loading }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Box flex={1}>
          <Typography fontSize={"1.2rem"} fontWeight={"600"}>
            Em aprovação na etapa {currentStep}
          </Typography>
        </Box>
        <IconButton
          sx={{ m: -1 }}
          disableTouchRipple
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse unmountOnExit in={expanded}>
        <Stack direction={"column"} pl={0.5}>
          {loading
            ? stepApprovers?.map((_, index) => (
                <Skeleton key={index.toString()} height={52} />
              ))
            : stepApprovers?.map((user) => (
                <StepApproverItem key={user?._id} approver={user || {}} />
              ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default memo(StepApproversInfo);
